<template>
  <div class="rule">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="el-nav">
      <el-tab-pane label="课程管理" name="courses"></el-tab-pane>
      <el-tab-pane label="新增课程" name="create">
        <el-form :model="coursesForm" :rules="rules" ref="coursesForm" label-position="right" label-width="150px" class="addForm">
          <el-form-item label="标题" prop="title">
            <el-input class="width-input" v-model="coursesForm.title" placeholder="请输入课程标题" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="副标题" prop="subtitle">
            <el-input class="width-input" v-model="coursesForm.subtitle" placeholder="请输入课程副标题" maxlength="150"></el-input>
          </el-form-item>

          <el-form-item label="上传移动端封面图" prop="mb_cover_picture">
            <upload-file @successUrl="resultImg($event, 'mb_cover_picture')" :folder="'course/images'" :url="url"></upload-file>
          </el-form-item>

          <el-form-item label="上传PC端封面图" prop="pc_cover_picture">
            <upload-file @successUrl="resultImg($event, 'pc_cover_picture')" :folder="'course/images'" :url="url"></upload-file>
          </el-form-item>

          <el-form-item label="上传移动端背景图" prop="mb_back_picture">
            <upload-file @successUrl="resultImg($event, 'mb_back_picture')" :folder="'course/images'" :url="url"></upload-file>
          </el-form-item>

          <el-form-item label="讲师" prop="lecturer">
            <!-- <el-input class="basic-input" v-model="coursesForm.lecturer" placeholder="请输入课程讲师"></el-input> -->
            <el-select class="authorselect" v-model="coursesForm.author_user_id" clearable filterable placeholder="请搜索或选择讲师">
              <el-option v-for="item in authorsList" :key="item.id" :label="`${item.nickname}-${item.mobile}`" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程标签" prop="tag_ids" class="tag-input">
            <!--          <el-select v-model="coursesForm.tag_ids" multiple placeholder="请选择课程标签" class="basic-input" >-->
            <!--            <el-option-->
            <!--                v-for="item in tagsData"-->
            <!--                :key="item.id"-->
            <!--                :label="item.name"-->
            <!--                :value="item.id"-->
            <!--            >-->
            <!--            </el-option>-->
            <!--          </el-select>-->
            <el-tag v-for="tag in coursesForm.tags" :key="tag.id" closable :disable-transitions="false" @close="handleClose(tag)">
              {{ tag.name }}
            </el-tag>
            <el-select class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small" @change="handleInputConfirm">
              <el-option v-for="item in tagsData" :key="item.id" :label="item.name" :value="item.id" :disabled="item.disabled"> </el-option>
            </el-select>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增标签</el-button>
          </el-form-item>
          <el-form-item label="难度级别" prop="difficulty_level">
            <el-select v-model="coursesForm.difficulty_level" placeholder="请选择难度级别" class="basic-input">
              <el-option v-for="item in levelData" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="SEO关键词" prop="seo_keywords">
            <el-input class="width-input" v-model="coursesForm.seo_keywords" placeholder="请输入课程SEO关键词"></el-input>
          </el-form-item>
          <el-form-item label="SEO描述" prop="seo_description">
            <el-input class="width-input" v-model="coursesForm.seo_description" placeholder="请输入课程SEO描述"></el-input>
          </el-form-item>

          <el-form-item label="介绍" prop="about" class="ueditor-line-height">
            <div style="padding-right: 6px">
              <ueditor v-model="coursesForm.about" placeholder="请输入课程介绍" :folder="'course/images'"></ueditor>
            </div>
          </el-form-item>
          <div class="form-operation">
            <el-button class="form-operation-item" round @click="cancelFun">取消</el-button>
            <el-button class="form-operation-item" type="primary" round @click="saveSubmit" v-if="save_submit_status">保存</el-button>
            <el-button class="form-operation-item" type="primary" round :loading="true" v-else>保存中</el-button>
          </div>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import UploadFile from 'components/uploadFile';
import _ from 'lodash';
export default {
  name: 'Create',
  data() {
    return {
      activeName: 'create',
      levelData: [], // 等级存放
      tagsData: [], // 标签列表
      url: 'image/browse',
      authorsList: [],
      coursesForm: {
        title: '', // 标题
        subtitle: '', // 副标题
        mb_cover_picture: '', //上传移动端封面图
        pc_cover_picture: '', //上传PC端封面图
        mb_back_picture: '', // 上传移动端背景图
        author_user_id: '', //  讲师
        tag_ids: [], // 课程标签
        difficulty_level: '', // 难度级别
        seo_title: '', // SEO标题
        seo_keywords: '', // SEO关键词
        seo_description: '', //SEO描述
        about: '', //介绍
        tags: [],
      },
      rules: {
        title: [
          { required: true, message: '请输入课程标题', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' },
        ],
        mb_cover_picture: [{ required: true, message: '请上传移动端封面图', trigger: 'blur' }],
        pc_cover_picture: [{ required: true, message: '上传PC端封面图', trigger: 'blur' }],
        about: [
          { required: true, message: '请输入课程介绍', trigger: 'blur' },
          { max: 5000, message: '长度在 0-5000 个字符', trigger: 'blur' },
        ],
      }, // 验证
      save_submit_status: true,
      inputVisible: false,
      inputValue: '',
    };
  },
  created() {
    this.getTags();
    this.getDictionary();
    this.getAuthorsList();
  },
  methods: {
    getAuthorsList() {
      let url = this.$api.authors;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.authorsList = res.data.data;
        }
      });
    },
    // tab切换
    handleClick(tab, event) {
      if (tab.name === 'courses') {
        this.$router.push('/courses');
      }
    },
    // 获取标签
    getTags() {
      let url = this.$api.tagsCircle;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.tagsData = res.data.data;
        }
      });
    },
    // 获取等级
    getDictionary() {
      let url = this.$api.Dictionary + '?key=course_difficulty';
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          let arr = [];
          for (let i in res.data.data) {
            arr.push({
              label: res.data.data[i],
              value: i,
            });
          }
          this.levelData = arr;
          this.coursesForm.difficulty_level = this.levelData[0].value;
        }
      });
    },
    // 取消
    cancelFun() {
      history.go(-1);
      //window.location.href= window.location.origin + '/courses/courses'
    },
    // 保存
    saveSubmit: _.debounce(function () {
      console.log(this.coursesForm, '======================');
      this.$refs.coursesForm.validate((valid) => {
        if (valid) {
          this.save_submit_status = false;
          this.coursesForm.tag_ids = this.coursesForm.tags.map(function (item) {
            return item['id'];
          });
          this.$http
            .post(this.$api.Courses, this.coursesForm, true)
            .then((res) => {
              if (res.data.success) {
                this.$message.success('创建成功');
                setTimeout(() => {
                  this.$router.replace('/courses/courses');
                }, 1000);
              } else {
                this.save_submit_status = true;
                this.$message.error(res.data.message);
              }
            })
            .catch((error) => {
              // this.$message.error(error.response.data.message);
              this.save_submit_status = true;
            });
        } else {
          return false;
        }
      });
    }, 500),
    // 返回图片
    resultImg(url, type) {
      if (!url[url.length - 1]) {
        url[url.length - 1] = '';
      }
      if (type === 'mb_cover_picture') {
        this.coursesForm.mb_cover_picture = url[url.length - 1];
        this.$refs.coursesForm.clearValidate('mb_cover_picture');
      } else if (type === 'pc_cover_picture') {
        this.coursesForm.pc_cover_picture = url[url.length - 1];
        this.$refs.coursesForm.clearValidate('pc_cover_picture');
      } else if (type === 'mb_back_picture') {
        this.coursesForm.mb_back_picture = url[url.length - 1];
        this.$refs.coursesForm.clearValidate('mb_back_picture');
      }
    },
    //删除标签
    handleClose(tag) {
      this.coursesForm.tags.splice(this.coursesForm.tags.indexOf(tag), 1);
      this.tagsData.forEach(function (item, index) {
        if (item.id === tag.id) {
          item.disabled = false;
        }
      });
      this.$forceUpdate();
    },
    //新增标签
    showInput() {
      this.inputVisible = true;
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      let as = '';
      if (inputValue) {
        this.tagsData.forEach(function (item, index) {
          if (item.id === inputValue) {
            as = index;
          }
        });
        this.coursesForm.tags.push(this.tagsData[as]);
        this.tagsData[as].disabled = true;
        this.coursesForm.tags = this.coursesForm.tags.reduce((cur, next) => {
          this.coursesForm.tags[next.id] ? '' : (this.coursesForm.tags[next.id] = true && cur.push(next));
          return cur;
        }, []);
        // this.options.splice(as,1)
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
  },
  components: {
    UploadFile,
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/courses/index.less';
</style>
